











































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import DataTableActionComponent from '@/components/DataTableActionComponent.vue';
import SampleTableComponent from '@/components/SampleTableComponent.vue';
import hasFeature from '@/utils/feature';
import { Flavor } from '@/flavor/types';
import { flavor, flavorData } from '@/flavor';

@Component({
  components: {
    ViewLayoutComponent,
    InfoBoxComponent,
    DataTableComponent,
    DataTableActionComponent,
    SampleTableComponent,
  },
})
export default class SampleView extends Vue {
  showEditVariables : boolean = hasFeature('variablesConfig');
  flavor: Flavor = flavor;
  travelViewer = Flavor.travelViewer;
  appName: string = flavorData.name;

  onEditVariables() {
    this.$router.push({
      name: 'edit-sample-variables',
      params: { from: 'sample' },
    });
  }

  onBack() {
    this.$router.push('/survey');
  }

  onNext() {
    if (flavor === Flavor.travelViewer) {
      this.$router.push('/weighting');
    } else {
      this.$router.push('/analysis');
    }
  }
}
